import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';

import classes from './Layout.module.css';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar'
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';

class Layout extends Component {
    state = {
        showSideDrawer: false,
    };

    toggleDrawerHandler = () => {
        this.setState(state => {
            return { showSideDrawer: !state.showSideDrawer };
        });
    };

    render() {
        return (
            <Fragment>
                <Toolbar toggleDrawer={this.toggleDrawerHandler} isAuthenticated={this.props.isAuthenticated} />
                <SideDrawer
                    sideDrawerClosed={this.toggleDrawerHandler}
                    showSideDrawer={this.state.showSideDrawer}
                    isAuthenticated={this.props.isAuthenticated} />
                <main className={classes.content}>
                    {this.props.children}
                </main>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    }
}

export default connect(mapStateToProps)(Layout);