import axios from 'axios';

import * as actionTypes from './actionTypes';

const API_KEY = 'AIzaSyBYlxI08s8G50Hop3loqA918zFsVAtKLgs';

export const authStart = () => {
    return {
        type: actionTypes.AUTH
    }
}

export const authSuccess = (authData) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: authData.idToken,
        userId: authData.localId
    }
}

export const authFailure = (error) => {
    return {
        type: actionTypes.AUTH_FAILURE,
        error: error
    }
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');

    return {
        type: actionTypes.LOGOUT
    };
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
}

export const authenticate = (email, password, isSignIn, history, redirectPath) => {
    return dispatch => {
        dispatch(authStart());

        const authData = {
            email: email,
            password: password,
            returnSecureToken: true
        };

        let url = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${API_KEY}`;

        if (isSignIn)
            url = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${API_KEY}`;

        axios.post(url, authData)
            .then(response => {
                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
                localStorage.setItem('token', response.data.idToken);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', response.data.localId);
                dispatch(authSuccess(response.data));
                dispatch(checkAuthTimeout(response.data.expiresIn));
                history.push(redirectPath);
            })
            .catch(error => {
                dispatch(authFailure(error.response.data.error));
            })
    };
}

export const authRedirect = (path) => {
    return {
        type: actionTypes.AUTH_REDIRECT,
        authRedirect: path
    };
}

export const checkAuthentication = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token)
            dispatch(logout());
        else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            const userId = localStorage.getItem('userId');
            if (expirationDate >= new Date()) {
                dispatch(authSuccess({ idToken: token, localId: userId }));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime() / 1000)));
            }
            else
                dispatch(logout());
        }
    };
}