import * as actionTypes from '../actions/actionTypes';

const initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    authRedirect: '/'
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                token: action.token,
                userId: action.userId,
                error: null,
                loading: false
            };
        case actionTypes.AUTH_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case actionTypes.LOGOUT:
            return {
                ...state,
                token: null,
                userId: null
            };
        case actionTypes.AUTH_REDIRECT:
            return {
                ...state,
                authRedirect: action.authRedirect
            }
    
        default:
            return state;
    }
}

export default reducer;