import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Burger from '../../components/Burger/Burger';
import BuildControls from '../../components/Burger/BuildControls/BuildControls';
import Modal from '../../components/UI/Modal/Modal';
import OrderSummary from '../../components/Burger/OrderSummary/OrderSummary';
import axios from '../../axios-orders';
import Spinner from '../../components/UI/Spinner/Spinner';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as burgerBuilderActions from '../../store/actions/burgerBuilder';
import * as authActions from '../../store/actions/auth';

export class BurgerBuilder extends Component {
    state = {
        purchasing: false,
        loading: false,
    };

    componentDidMount() {
        this.props.onInitIngredients();
    }

    isPurchasable = () => {
        return Object.values(this.props.ingredients).some(ing => ing > 0);
    };

    purchaseHandler = () => {
        if (this.props.isAuthenticated)
            this.setState({ purchasing: true });
        else{
            this.props.onAuthRedirect('/checkout');
            this.props.history.push('/auth');
        }
    };

    purchaseCancelHandler = () => {
        this.setState({ purchasing: false });
    };

    purchaseContinueHandler = () => {
        this.props.history.push('/checkout');
    };

    render() {
        const disabledInfo = {
            ...this.props.ingredients
        };

        for (let key in disabledInfo)
            disabledInfo[key] = disabledInfo[key] <= 0

        let burger = <Spinner />;
        let orderSummary = null;

        if (this.props.ingredients) {
            burger = (
                <Fragment>
                    <Burger ingredients={this.props.ingredients} />

                    <BuildControls
                        addIngredient={this.props.addIngredient}
                        removeIngredient={this.props.removeIngredient}
                        disabledInfo={disabledInfo}
                        price={this.props.totalPrice}
                        purchasable={this.isPurchasable()}
                        purchase={this.purchaseHandler}
                        isAuthenticated={this.props.isAuthenticated} />
                </Fragment>
            );
            orderSummary = (
                <OrderSummary
                    ingredients={this.props.ingredients}
                    cancel={this.purchaseCancelHandler}
                    continue={this.purchaseContinueHandler}
                    totalPrice={this.props.totalPrice} />
            );
        }

        if (this.state.loading)
            orderSummary = <Spinner />;

        return (
            <Fragment>
                <Modal show={this.state.purchasing} closed={this.purchaseCancelHandler}>
                    {orderSummary}
                </Modal>

                {burger}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        ingredients: state.burger.ingredients,
        totalPrice: state.burger.totalPrice,
        isAuthenticated: state.auth.token !== null
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addIngredient: ingredientName => { dispatch(burgerBuilderActions.addIngredient(ingredientName)) },
        removeIngredient: ingredientName => { dispatch(burgerBuilderActions.removeIngredient(ingredientName)) },
        onInitIngredients: () => dispatch(burgerBuilderActions.initIngredients()),
        onAuthRedirect: (path) => dispatch(authActions.authRedirect(path))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(BurgerBuilder, axios));