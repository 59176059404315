import React, { Fragment, Component } from 'react';

import Modal from '../../components/UI/Modal/Modal';

const withErrorHandler = (WrappedComponent, axios) => {
    return class extends Component {
        constructor(props) {
            super(props);

            this.reqInterceptor = axios.interceptors.request.use(request => {
                this.setState({ error: null });

                return request;
            })
            this.respInterceptor = axios.interceptors.response.use(response => response, error => {
                this.setState({ error: error });

                return Promise.reject(error);
            });

            this.state = {
                error: null
            };
        }

        componentWillUnmount() {
            axios.interceptors.request.eject(this.reqInterceptor);
            axios.interceptors.response.eject(this.respInterceptor);
        }

        dismissModalHandler = () => {
            this.setState({ error: null });
        };

        render() {
            return (
                <Fragment>
                    <Modal show={this.state.error} closed={this.dismissModalHandler}>
                        {this.state.error ? this.state.error.message : null}
                    </Modal>
                    <WrappedComponent {...this.props} />
                </Fragment>
            );
        }
    }
};

export default withErrorHandler;