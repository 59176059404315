import React, { Fragment } from 'react';

import Button from '../../UI/Button/Button';

const orderSummary = props => {
    const ingredientSummary = [];

    for (let key in props.ingredients)
        ingredientSummary.push((
            <li key={key}>
                <span style={{textTransform: 'capitalize'}}>{key}</span>: {props.ingredients[key]}
            </li>
        ));

    return (
        <Fragment>
            <h3>Your Order</h3>
            <p>A delicious burger with the following ingredients:</p>
            <ul>
                {ingredientSummary}
            </ul>
            <p><strong>Total Price: ${props.totalPrice.toFixed(2)}</strong></p>
            <p>Continue to Checkout?</p>
            <Button btnType="alert" clicked={props.cancel}>Cancel</Button>
            <Button btnType="success" clicked={props.continue}>Continue</Button>
        </Fragment>
    );
};

export default orderSummary;