import React from 'react';

import classes from './Burger.module.css';
import BurgerIngredient from './BurgerIngredient/BurgerIngredient';

const burger = props => {
    let ingredientsArray = [];

    for (let key in props.ingredients) {
        for (let i = 0; i < props.ingredients[key]; i++) {
            ingredientsArray.push(<BurgerIngredient key={key + i} type={key} />);
        }
    }

    if (!ingredientsArray.length)
        ingredientsArray = <p>Please start adding ingredients!</p>;

    return (
        <div className={classes.burger}>
            <BurgerIngredient type="bread-top" />
            {ingredientsArray}
            <BurgerIngredient type="bread-bottom" />
        </div>
    );
};

export default burger;