import React, { Fragment } from 'react';

import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import classes from './SideDrawer.module.css'
import Backdrop from '../../UI/Backdrop/Backdrop';

const sideDrawer = props => {
    let sdClasses = [classes.sideDrawer, classes.closed];

    if (props.showSideDrawer)
        sdClasses = [classes.sideDrawer, classes.open];

    return (
        <Fragment>
            <Backdrop show={props.showSideDrawer} click={props.sideDrawerClosed} />
            <div className={sdClasses.join(' ')} onClick={props.sideDrawerClosed}>
                <div className={classes.logo}>
                    <Logo />
                </div>
                <nav>
                    <NavigationItems isAuthenticated={props.isAuthenticated} />
                </nav>
            </div>
        </Fragment>
    );
};

export default sideDrawer;